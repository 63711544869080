const sliderProducts = () => {
	let sliderTopSwiper = new Swiper('.slider-products__container', {
		slidesPerView: 1,
		spaceBetween: 0,
		loop: true,
		pagination: {
			el: '.slider-products__pagination',
			clickable: true,
		},
		navigation: {
			nextEl: '.slider-products__next',
			prevEl: '.slider-products__prev',
		},
	});
}

export default sliderProducts;