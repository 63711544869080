const videoBG = () => {	
	let video = document.querySelectorAll('.video-bg video');
	let windowWidth = document.documentElement.clientWidth;

	if(windowWidth < 767) {
		video.forEach(item => {
			let videoMp4 = item.querySelector('[data-type="mp4"]');
			let videoWebm = item.querySelector('[data-type="webm"]');

			videoMp4.src = 'images/' + item.id + '--sm.mp4';
			videoWebm.src = 'images/' + item.id + '--sm.webm';

			item.load();
		});
	}	else {
		video.forEach(item => {
			let videoMp4 = item.querySelector('[data-type="mp4"]');
			let videoWebm = item.querySelector('[data-type="webm"]');

			videoMp4.src = 'images/' + item.id + '.mp4';
			videoWebm.src = 'images/' + item.id + '.webm';

			item.load();
		});
	}
}

export default videoBG;