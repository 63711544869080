const top = () => {
	let top = document.querySelector('.top');
	let windowHeight = document.documentElement.clientHeight;
	let heightCompensation = top.offsetHeight - windowHeight;
	let topScroll = document.querySelector('.top__scroll');

	if(heightCompensation > 0) {
		topScroll.style.marginBottom = heightCompensation + 'px';
	}
	// console.log(top.offsetHeight);
	// console.log(windowHeight);
	// console.log(heightCompensation);
}

export default top;