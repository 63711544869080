import sliderTop from './slider-top/slider-top';
import mountains from './mountains/mountains';
import sliderProducts from './slider-products/slider-products';
import top from './top/top';
import videoBG from './video-bg/video-bg';

window.addEventListener('DOMContentLoaded', () => {
	videoBG();
	sliderTop();
	mountains();
	sliderProducts();
	top();
});

window.onload = function() {
	top();

	// yall({
  //   observeChanges: true
  // });

	document.body.classList.add('loaded--hiding');

	window.setTimeout(function () {
		document.body.classList.add('loaded');
		document.body.classList.remove('loaded--hiding');
	}, 500);
};

window.addEventListener('resize', () => {
	top();
});

// window.addEventListener('scroll', function() {
// 	console.log(this.pageYOffset);

// });