const mountains = () => {
	let mountainsBack = document.querySelector('.mountains__back img');
	let mountainsSnow = document.querySelector('.mountains__snow img');
	let mountainsCloud1 = document.querySelector('.mountains__cloud1');
	let mountainsCloud2 = document.querySelector('.mountains__cloud2');
	let mountainsForest = document.querySelector('.mountains__forest');

	let m1 = new simpleParallax(mountainsBack, {
		orientation: 'up',
		overflow: true
	});

	let f = new simpleParallax(mountainsForest, {
		orientation: 'up',
		overflow: true
	});

	// let m2 = new simpleParallax(mountainsSnow, {
	// 	orientation: 'down',
	// 	overflow: true,
	// 	scail: 1.2		
	// });

	let c1 = new simpleParallax(mountainsCloud1, {
		orientation: 'down',
		overflow: true,
		scail: 2.3
	});

	let c2 = new simpleParallax(mountainsCloud2, {
		orientation: 'down',
		overflow: true,
		customWrapper: '.mountains',
		scail: 1.5
	});
};

export default mountains;